import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import React, { useEffect } from "react";
import { useCampaign } from "../Campaign/provider";
import { isCampaignEqual } from "../Campaign/types";
// Sets up the event tracking for pages
// For now only campaign event tracking which is stored in a provider and used
// to attach it to payments in the checkout process
export var EventsTracking = function(param) {
    var page = param.page;
    var ref;
    var ref1 = useCampaign(), campaign = ref1.campaign, setCampaign = ref1.setCampaign;
    var campaignEventTracking = page === null || page === void 0 ? void 0 : (ref = page.events) === null || ref === void 0 ? void 0 : ref.find(function(event) {
        return event._type == "campaignEventTracking";
    });
    useEffect(function() {
        if (!setCampaign) return;
        var newCampaign = campaign;
        if (campaignEventTracking) newCampaign = _object_spread_props(_object_spread({}, newCampaign), {
            campaign_organiser: campaignEventTracking.campaignOrganiser,
            campaign_category: campaignEventTracking.campaignCategory,
            campaign_name: campaignEventTracking.campaignName,
            data_sharing_disabled: campaignEventTracking.dataSharingDisabled
        });
        if (newCampaign && !isCampaignEqual(newCampaign, campaign)) setCampaign(newCampaign);
    }, [
        setCampaign,
        campaignEventTracking,
        campaign
    ]);
    return null;
};
