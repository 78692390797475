export var CAMPAIGN_COOKIE_NAME = "@gwwc/campaigns";
export var campaignKeys = [
    "campaign_organiser",
    "campaign_category",
    "campaign_name",
    "url_campaign_name",
    "data_sharing_disabled", 
];
// Don't use lodash isEqual because it treats undefined as different from a missing key
export var isCampaignEqual = function(campaign, newCampaign) {
    if (campaign === undefined && newCampaign === undefined) return true;
    if (!campaign || !newCampaign) return false;
    return campaignKeys.every(function(key) {
        return campaign[key] === newCampaign[key];
    });
};
