import { throttle } from "lodash";
import { useEffect, useState } from "react";
import { anchorIdFromTextBlock, textFromTextBlock } from "./utils";
var DEFAULT_TOC_STYLES = [
    "h1",
    "h2",
    "h3",
    "h4"
];
/**
 * Extracts TocItems from article.body
 * @param article
 * @param extractedTextStyles
 * @returns TocItem[]
 */ export var useExtractedTocItemsFromArticle = function(article) {
    var extractedTextStyles = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : DEFAULT_TOC_STYLES;
    if (!article.body) {
        return [];
    }
    // Extract desired textBlocks: each will have a `.style` that is in extractedTextStyles
    var tocTextBlocks = article.body.filter(function(c) {
        return extractedTextStyles.includes(c.style);
    });
    // Convert tocTextBlocks into TocItems: `.depth` is based on position within extractedTextStyles
    return tocTextBlocks.map(function(c) {
        return {
            style: c.style,
            depth: extractedTextStyles.indexOf(c.style),
            text: textFromTextBlock(c),
            anchorId: anchorIdFromTextBlock(c)
        };
    });
};
/**
 * We want to know which TocItem should be considered to be "in view".
 * - A TocItem is in view if it or the section until the next tocItem is visible in the viewport
 *
 * Intersection observers can't be used as these sections between TocItems are not actually in the DOM,
 * so we need to use the more intensive scroll event listeners instead
 *
 * @param TocItem[]
 * @returns TocItem
 */ export var useTocItemInView = function(tocItems) {
    var ref = useState(tocItems.length ? tocItems[0] : undefined), tocItemInView = ref[0], setTocItemInView = ref[1];
    useEffect(function() {
        var scrollListener = function scrollListener() {
            // Array of tocItems sorted by distance from the top of the viewport
            var tocItemsWithTop = tocItems.map(function(tocItem) {
                var ref;
                return {
                    top: (ref = document.getElementById(tocItem.anchorId)) === null || ref === void 0 ? void 0 : ref.getBoundingClientRect().top,
                    tocItem: tocItem
                };
            })// Discard items that could not be found or don't have a BoundingClientRect
            .filter(function(a) {
                return !!a.top;
            }).sort(function(a, b) {
                return a.top - b.top;
            });
            // For the bottom, use the top of the next item
            var tocItemsWithBottom = tocItemsWithTop.map(function(param, index) {
                var tocItem = param.tocItem;
                return {
                    tocItem: tocItem,
                    bottom: index === tocItemsWithTop.length - 1 ? Infinity : tocItemsWithTop[index + 1].top
                };
            });
            // The threshold is based on styling
            // needs to be a bit more than the topScrollPadding used for the ToC (currently 8 * 14 )
            var threshold = 8 * 18;
            // Find the first (so top-most) item that has the bottom below the threshold
            // Example: TocItem2 is the itemInView
            // ==================== | ===========================
            //        Element       | Offset from top of viewport
            // ==================== | ===========================
            // -- TocItem1 start -- | < 0
            // bla bla bla          |
            // -- TocItem2 start -- | < 0, so TocItem1.bottom < 0
            // bla bla bla          |
            // bla bla bla          |
            // --- Header Start --- | = 0
            // ---- Header End ---- | = 64
            // bla bla bla          |
            // ----- Threshold ---- | 8 * 18 = 144
            // bla bla bla          |
            // bla bla bla          |
            // -- TocItem3 start -- | > threshold, so TocItem2.bottom > threshold
            // bla bla bla          |
            // bla bla bla          |
            var itemInView = tocItemsWithBottom.find(function(t) {
                return t.bottom > threshold;
            });
            if (itemInView === null || itemInView === void 0 ? void 0 : itemInView.tocItem) {
                setTocItemInView(itemInView.tocItem);
            }
        };
        // Listener is expensive, and 'scroll' gets fired a lot
        var throttledListener = throttle(scrollListener, 250);
        document.addEventListener("scroll", throttledListener, {
            passive: true
        });
        // Call listener to ensure correct activeHeader is set before any scroll has happened
        throttledListener();
        return function() {
            return document.removeEventListener("scroll", throttledListener);
        };
    }, [
        tocItems
    ]);
    return tocItemInView;
};
