import { calculateIncomePercentile as calculateHRAIIncomePercentile } from "../../../HRAICalculator/utils";
export var calculateWealthUsd = function(exchangeRate, wealth, numAdults, numChildren) {
    var parsedWealth = Number(wealth || 0);
    var numHousehold = Number(numAdults || 1) + Number(numChildren || 0);
    var wealthUsd = parsedWealth / exchangeRate;
    var wealthUsdPerPerson = wealthUsd / numHousehold;
    return wealthUsdPerPerson;
};
export var calculateIncomePercentile = function(income, countryCode, numAdults, numChildren) {
    var ref;
    var incomePercentile = (ref = calculateHRAIIncomePercentile({
        income: income,
        countryCode: countryCode,
        numAdults: numAdults,
        numChildren: numChildren
    })) === null || ref === void 0 ? void 0 : ref.incomeTopPercentile;
    return incomePercentile;
};
var _incomePercentileLowerBound, _incomePercentileUpperBound, _wealthUSDLowerBound, _wealthUSDUpperBound;
export var calculatePercentileBucketId = function(buckets, incomePercentile, wealthUsdPerPerson) {
    var ref;
    return (ref = buckets.find(function(bucket) {
        return incomePercentile >= ((_incomePercentileLowerBound = bucket.incomePercentileLowerBound) !== null && _incomePercentileLowerBound !== void 0 ? _incomePercentileLowerBound : 0) && incomePercentile <= ((_incomePercentileUpperBound = bucket.incomePercentileUpperBound) !== null && _incomePercentileUpperBound !== void 0 ? _incomePercentileUpperBound : 100) && wealthUsdPerPerson >= ((_wealthUSDLowerBound = bucket.wealthUSDLowerBound) !== null && _wealthUSDLowerBound !== void 0 ? _wealthUSDLowerBound : 0) && wealthUsdPerPerson <= ((_wealthUSDUpperBound = bucket.wealthUSDUpperBound) !== null && _wealthUSDUpperBound !== void 0 ? _wealthUSDUpperBound : Infinity);
    })) === null || ref === void 0 ? void 0 : ref.sectionId;
};
