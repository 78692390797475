/**** TYPES OF DONORS ****/ export var donorTypeOptions = [
    {
        type: "newDonor",
        label: "I'm new to donating"
    },
    {
        type: "nonPledger",
        label: "I donate but haven't pledged"
    },
    {
        type: "trialPledger",
        label: "I'm a \uD83D\uDD39Trial Pledger"
    },
    {
        type: "lifetimePledger",
        label: "I'm a \uD83D\uDD3810% Pledger"
    }, 
];
/**** CONCERN OPTIONS ****/ export var MAX_NUM_CONCERN_SELECTION = 3;
export var concernOptions = {
    newDonor: [
        "Can one person really make a difference?",
        "Can I achieve real impact through my donations?",
        "I have some concerns - why should I donate?",
        "What is effective giving?",
        "Which charities are most effective?",
        "How much should I give?", 
    ],
    nonPledger: [
        "Unsure about the value of donating",
        "Unsure about the value of pledging",
        "Financial concerns",
        "Long-term commitment / Flexibility concerns",
        "Details of the pledge are unclear",
        "Don't know where to donate", 
    ],
    trialPledger: [
        "What happens after the trial?",
        "Inspiring others to give",
        "Talking to others about my pledge",
        "Optimising finances",
        "Best charities to support",
        "Questions about donating", 
    ],
    lifetimePledger: [
        "Talking to others about my pledge",
        "Inspiring others to give",
        "Optimising tax-deductibility",
        "Legacy giving options",
        "Best charities to support",
        "Questions about donating", 
    ]
};
// Concern options that trigger an additional step in the quiz
export var concernOptionsWithAdditionalSteps = [
    "Don't know where to donate",
    "Questions about donating",
    "Unsure about the value of donating", 
];
/**** CONCERN-TO-ARTICLE MAPPINGS ****/ export var MAX_NUM_ARTICLES_SHOWN = 3;
export var articles = [
    {
        title: "Effective Giving 101",
        summary: "Learn about the fundamentals of effective giving and how to maximise your charitable impact.",
        link: "https://www.givingwhatwecan.org/effective-giving-101",
        concerns: [
            "What is effective giving?"
        ]
    },
    {
        title: "Achieving Impact Through Donations",
        summary: "Explore the compelling reasons for charitable giving and its concrete impact on the world.",
        link: "https://www.givingwhatwecan.org/an-introduction-to-the-power-of-effective-giving",
        concerns: [
            "Can I achieve real impact through my donations?"
        ]
    },
    {
        title: "Can One Person Really Make a Difference?",
        summary: "Discover how individual donations can create significant positive change in the world.",
        link: "https://www.givingwhatwecan.org/blog/can-one-person-really-make-a-difference",
        concerns: [
            "Can one person really make a difference?"
        ]
    },
    {
        title: "Best Charities to Donate to in 2024",
        summary: "Find out which charities are most effective at creating positive impact with your donations.",
        link: "https://www.givingwhatwecan.org/best-charities-to-donate-to-2024",
        concerns: [
            "Which charities are most effective?",
            "Best charities to support", 
        ]
    },
    {
        title: "How Much Money Should We Donate to Charity?",
        summary: "Learn about different approaches to determining how much to give and find a method that works for you.",
        link: "https://www.givingwhatwecan.org/blog/how-much-money-should-we-donate-to-charity",
        concerns: [
            "How much should I give?"
        ]
    },
    {
        title: "Common Concerns About Donating to Charity",
        summary: "Explore answers to frequently asked questions about charitable giving and its impact.",
        link: "https://www.givingwhatwecan.org/get-involved/common-concerns-about-donating-to-charity",
        concerns: [
            "I have some concerns - why should I donate?"
        ]
    },
    {
        title: "Try a Giving What We Can Trial Pledge",
        summary: "Learn about our Trial Pledge, a flexible way to experience the benefits of committed giving.",
        link: "https://www.givingwhatwecan.org/get-involved/trial-pledge",
        concerns: [
            "Long-term commitment / Flexibility concerns",
            "What happens after the trial?",
            "Financial concerns", 
        ]
    },
    {
        title: "5 Things You've Got Wrong About the Giving What We Can Pledge",
        summary: "Clear up common misconceptions about the pledge and understand how it works.",
        link: "https://www.givingwhatwecan.org/blog/5-things-you-ve-got-wrong-about-the-giving-what-we-can-pledge",
        concerns: [
            "Details of the pledge are unclear"
        ]
    },
    {
        title: "Share Our Ideas",
        summary: "Discover ways to spread the message of effective giving and inspire others to join the movement.",
        link: "https://www.givingwhatwecan.org/get-involved/share-our-ideas",
        concerns: [
            "Inspiring others to give"
        ]
    },
    {
        title: "Advocacy Assets",
        summary: "Access resources and tools to help you effectively communicate about your pledge and inspire others.",
        link: "https://www.givingwhatwecan.org/get-involved/advocacy-assets",
        concerns: [
            "Talking to others about my pledge"
        ]
    },
    {
        title: "Tax Deductibility of Donations",
        summary: "Learn how to optimise your charitable giving for tax purposes and maximise your impact.",
        link: "https://www.givingwhatwecan.org/get-involved/tax-deductibility",
        concerns: [
            "Optimising finances",
            "Optimising tax-deductibility"
        ]
    },
    {
        title: "Leaving a Legacy: Bequests and Planned Giving",
        summary: "Explore options for including charitable giving in your estate planning and leaving a lasting impact.",
        link: "https://www.givingwhatwecan.org/get-involved/bequests",
        concerns: [
            "Legacy giving options"
        ]
    },
    {
        title: "Why Pledge?",
        summary: "Discover the benefits of making a giving pledge and how it can increase your impact over time.",
        link: "https://www.givingwhatwecan.org/why-pledge",
        concerns: [
            "Unsure about the value of pledging"
        ]
    }, 
];
