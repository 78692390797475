/*
  Wrappers around Intl.NumberFormat (provided by react-intl as <FormattedNumber />)
  with preferred defaults
*/
import React from 'react'
import { FormattedNumber, FormattedDate } from 'react-intl'

export function prettyDate(date: Date): JSX.Element {
  return (
    <FormattedDate
      month="long"
      year="numeric"
      day="numeric"
      timeZone="UTC"
      value={date}
    />
  )
}

export function prettyCurrency(
  amount: number,
  currencyCode: string,
): JSX.Element {
  return (
    <FormattedNumber value={amount} style="currency" currency={currencyCode} />
  )
}

type FormattedNumberProps = React.ComponentProps<typeof FormattedNumber>
type PrettyPercentagePropsWithPercentage = { percentage: number } & Omit<
  FormattedNumberProps,
  'value'
>
type PrettyPercentageProps =
  | FormattedNumberProps
  | PrettyPercentagePropsWithPercentage

/**
 * Guard to test if we've supplied props with value or percentage to
 * PrettyPercentage
 */
function isPrettyPercentagePropsWithValue(
  props: PrettyPercentageProps,
): props is FormattedNumberProps {
  return (
    typeof (props as FormattedNumberProps).value === 'number' &&
    typeof (props as PrettyPercentagePropsWithPercentage).percentage ===
      'undefined'
  )
}

/**
 * Display a percentage nicely
 *
 * Value can be specified as either:
 *
 * - value: a value between 0 and 1 (standard behaviour for Intl.NumberFormat())
 *
 * - percentage: a value between 1 and 100 (useful as many objects in the
 * codebase express percentages like this)
 */
export const PrettyPercentage: React.FC<PrettyPercentageProps> = (props) => {
  if (isPrettyPercentagePropsWithValue(props)) {
    return <FormattedNumber {...props} />
  } else {
    const { percentage, ...rest } = props
    const value = percentage / 100
    return <FormattedNumber value={value} {...rest} style="percent" />
  }
}
